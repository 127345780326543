var audio = new Audio("/sfx/HUMAN_VOCAL_Male_Curse_Evil_11.mp3");

const baseUrl = "https://www.cthulhuaigod.com/"; // Ensure this matches your deployed URL
document.getElementById("login-button").addEventListener("click", () => {
  document.querySelector(".left-section").style.visibility = "hidden";
  document.querySelector(".Rules").style.visibility = "hidden";
  document.querySelector(".right-section").style.visibility = "visible";
  document.querySelector(".return-button").style.visibility = "visible";
  document.querySelector(".login-button").style.visibility = "hidden";
});

document.getElementById("return-button").addEventListener("click", () => {
  document.querySelector(".Rules").style.visibility = "visible  ";
  document.querySelector(".left-section").style.visibility = "visible";
  document.querySelector(".right-section").style.visibility = "hidden";
  document.querySelector(".return-button").style.visibility = "hidden";
  document.querySelector(".login-button").style.visibility = "visible";
});
// Check if user is authenticated
async function checkAuthStatus() {
  try {
    const response = await fetch(`${baseUrl}/api/auth-status`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();

    if (data.authenticated) {
      document.getElementById("connectTwitter").style.display = "none";
      document.getElementById("login-group-id").style.display = "block";
      document.getElementById("login-button").textContent = "Profile";
      window.isAuthenticated = true;
      displayAuthenticatedUsername();
      fetchUserWickedPoints();
    } else {
      window.isAuthenticated = false;
    }
  } catch (error) {
    console.error("Error checking authentication status:", error);
  }
}

// Redirect to Twitter authentication if not authenticated
document
  .getElementById("connectTwitter")
  .addEventListener("click", async () => {
    try {
      const response = await fetch(`${baseUrl}/api/auth`);
      const data = await response.json();
      window.location.href = data.url; // Redirects to Twitter login URL
    } catch (error) {
      console.error("Error initiating authentication:", error);
    }
  });
document
  .getElementById("connectTwitterModal")
  .addEventListener("click", async () => {
    try {
      const response = await fetch(`${baseUrl}/api/auth`);
      const data = await response.json();
      window.location.href = data.url; // Redirects to Twitter login URL
    } catch (error) {
      console.error("Error initiating authentication:", error);
    }
  });
async function fetchUserWickedPoints() {
  try {
    const response = await fetch("/api/get-wicked-points", {
      method: "GET",
      credentials: "include", // Include cookies for authentication
    });

    if (!response.ok) {
      console.error("Error fetching Wicked Points.");
      return;
    }

    const { wicked_points } = await response.json();
    document.getElementById("bottom-wicked-points").textContent =
      wicked_points || 0; // Update the Wicked Points display
  } catch (error) {
    console.error("Error fetching Wicked Points:", error);
  }
}

// Fetch Wicked Points on page load
document.addEventListener("DOMContentLoaded", fetchUserWickedPoints);
// Show authentication modal if needed and process "Curse" form submission
document.addEventListener("DOMContentLoaded", function () {
  const replyButton = document.getElementById("replybutton");
  const tweetForm = document.getElementById("tweetForm");

  // Trigger form submission when clicking the custom reply button
  replyButton.addEventListener("click", function () {
    tweetForm.dispatchEvent(new Event("submit", { cancelable: true }));
  });

  // Handle the form submission
  tweetForm.addEventListener("submit", async function (event) {
    event.preventDefault();

    const tweetUrl = document.getElementById("tweet_url").value.trim();
    const messageDiv = document.getElementById("message");
    if (!window.isAuthenticated) {
      document.getElementById("authModal").style.display = "flex";
      return;
    }
    try {
      const response = await fetch(`/api/reply`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tweet_url: tweetUrl }),
        credentials: "include", // Ensure cookies are sent
      });

      const result = await response.json();

      if (response.ok) {
        messageDiv.textContent = result.message;
        messageDiv.style.color = "white";
        audio.play();
        // Refresh leaderboard after a successful curse reply
        fetchLeaderboard();
        fetchUserWickedPoints();
        // Update the displayed curse limit if it was decremented
        const curseLimitDisplay = document.getElementById("bottom-curse-limit");
        let currentLimit = parseInt(curseLimitDisplay.textContent, 10) || 0;
        curseLimitDisplay.textContent = Math.max(currentLimit - 1, 0);
      } else {
        messageDiv.textContent = `Error: ${result.error}`;
        messageDiv.style.color = "orange";
      }
    } catch (error) {
      messageDiv.textContent = `Error: ${error.message}`;
      messageDiv.style.color = "orange";
    }

    setTimeout(() => {
      messageDiv.textContent = ""; // Clear the message after a short delay
    }, 3000);
  });
});
// Initialize on page load
window.addEventListener("load", checkAuthStatus);

// Close authentication modal
document.getElementById("cancelModal").addEventListener("click", () => {
  document.getElementById("authModal").style.display = "none";
});

// Fetch and display the leaderboard
async function fetchLeaderboard() {
  try {
    const response = await fetch("/api/leaderboard");
    if (!response.ok) throw new Error("Failed to fetch leaderboard");

    const leaderboard = await response.json();

    // Check if leaderboard is an array
    if (!Array.isArray(leaderboard)) throw new Error("Invalid response format");

    const leaderboardContent = document.getElementById("leaderboard-content");
    leaderboardContent.innerHTML = leaderboard
      .map(
        (user, index) => `
        <div class="leaderboard-row">
          <span class="rank">${index + 1}</span>
          <span class="username">${user.username}</span>
          <span class="count">${user.wicked_points}</span>
        </div>
      `
      )
      .join("");
  } catch (error) {
    console.error("Error displaying leaderboard:", error);
    document.getElementById(
      "leaderboard-content"
    ).innerHTML = `<p>Error loading leaderboard</p>`;
  }
}
// Helper function to retrieve a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
// After checkAuthStatus is called, add this function to set the username
async function displayAuthenticatedUsername() {
  const username = getCookie("username"); // Fetch username from cookies
  if (username) {
    document.getElementById("bottom-username-display").innerText = username;
  } else {
    console.log("Username cookie not found"); // Debugging log
  }
}

// Fetch leaderboard on page load

document.addEventListener("DOMContentLoaded", fetchLeaderboard);

document.addEventListener("DOMContentLoaded", async function () {
  const tweetForm = document.getElementById("tweetCheck");
  const messageDiv = document.getElementById("message_tweet_check");
  const curseLimitDisplay = document.getElementById("bottom-curse-limit");
  const checkButton = document.getElementById("check-button");
  const timeoutDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
  const localStorageKey = "tweetCheckTimeout";

  // Check if there's an existing timeout on page load
  const existingTimeout = localStorage.getItem(localStorageKey);
  if (existingTimeout) {
    const timeLeft = parseInt(existingTimeout) - Date.now();
    if (timeLeft > 0) {
      disableFormForTimeout(timeLeft);
    }
  }

  // Add event listener to the tweet check form
  tweetForm.addEventListener("submit", async function (event) {
    event.preventDefault();

    let tweetUrl = document.getElementById("tweet_url_1").value.trim();
    if (!tweetUrl) {
      messageDiv.textContent = "Please enter a Tweet URL.";
      messageDiv.style.color = "orange";
      hideMessageWithTimeout();
      return;
    }

    if (!tweetUrl.startsWith("http://") && !tweetUrl.startsWith("https://")) {
      tweetUrl = "https://" + tweetUrl;
    }

    const match = tweetUrl.match(
      /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)$/
    );
    if (!match) {
      messageDiv.textContent = "Invalid Tweet URL format.";
      messageDiv.style.color = "orange";
      hideMessageWithTimeout();
      return;
    }

    try {
      const response = await fetch("/api/validate-tweet-and-grant", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tweetUrl }),
      });

      const data = await response.json();
      if (response.ok && data.success) {
        messageDiv.textContent = data.message;

        // Increment the displayed curse limit by 1
        let currentLimit = parseInt(curseLimitDisplay.textContent, 10) || 0;
        curseLimitDisplay.textContent = currentLimit + 1;
        fetchUserWickedPoints();
        audio.play();
        // Disable the form after successful check
        disableFormForTimeout(timeoutDuration);
      } else {
        messageDiv.textContent = data.message || "Failed to validate tweet.";
      }
    } catch (error) {
      console.error("Error validating tweet:", error);
      messageDiv.textContent = "Error verifying tweet. Please try again.";
    }

    hideMessageWithTimeout();
  });

  function disableFormForTimeout(duration) {
    const endTime = Date.now() + duration;
    checkButton.disabled = true;
    localStorage.setItem(localStorageKey, endTime);

    const interval = setInterval(() => {
      const timeLeft =
        parseInt(localStorage.getItem(localStorageKey)) - Date.now();
      if (timeLeft <= 0) {
        clearInterval(interval);
        checkButton.disabled = false;
        messageDiv.textContent = "";
        localStorage.removeItem(localStorageKey);
      } else {
        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        messageDiv.textContent = `Please wait ${minutes}:${seconds
          .toString()
          .padStart(2, "0")} before checking again.`;
        messageDiv.style.color = "orange";
      }
    }, 1000);
  }

  checkButton.addEventListener("click", function () {
    if (checkButton.disabled) {
      const timeLeft =
        parseInt(localStorage.getItem(localStorageKey)) - Date.now();
      if (timeLeft > 0) {
        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        messageDiv.textContent = `Please wait ${minutes}:${seconds
          .toString()
          .padStart(2, "0")} before checking again.`;
        messageDiv.style.color = "orange";
      }
    }
  });

  function hideMessageWithTimeout() {
    setTimeout(() => {
      messageDiv.textContent = "";
    }, 3000);
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const sacrificeButton = document.getElementById("sacrificeButton");

  if (sacrificeButton) {
    sacrificeButton.addEventListener("click", function () {
      // Define the tweet content
      const tweetText = encodeURIComponent(
        "I give my blood to @cthulhuaigod 🩸#cthulhuaigod"
      );

      // Construct the Twitter web intent URL
      const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

      // Open a popup window with specified dimensions
      const width = 600;
      const height = 400;
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 2;

      window.open(
        twitterUrl,
        "TweetWindow",
        `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,status=no`
      );
    });
  } else {
    console.warn("Sacrifice button not found.");
  }
});
document.addEventListener("DOMContentLoaded", function () {
  const connectAccountButton = document.getElementById("connectAccount");

  connectAccountButton.addEventListener("click", function () {
    window.open("https://x.com/Cthulhuaigod", "_blank");
  });
});
